import React from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";

import { Typography } from "@/components/Typography";
import { Form } from "@/components/Form";

import postRecoverPasswordPostHandler from "@/interface/api/postRecoverPassword";

import { onBindClick } from "@/utils/formHelper";
import { useToast } from "@/hooks/useToast";
import { useApi } from "@/hooks/useApi";

import { recoverPasswordValidation } from "@/utils/validators/recover-password";

import { RecoverPasswordProps } from "./types";
import { PostRecoverPasswordResponse } from "@/interface/api/postRecoverPassword/types";

export default function RecoverPassword({
  onSetFormType,
  onRecoverPassword,
  recoverPasswordData,
}: RecoverPasswordProps) {
  const { toast } = useToast();

  const [formObject, setFormObject] = React.useState<
    { values: FieldValues; methods: UseFormReturn } | undefined
  >(undefined);

  const onRecoverPasswordRequest = (values: FieldValues) => {
    request({
      email: values.email,
      password: values.password,
    });
  };

  const schema = recoverPasswordValidation(recoverPasswordData);
  const { request, loading, data, error } = useApi<PostRecoverPasswordResponse>(
    postRecoverPasswordPostHandler.postRecoverPassword
  );

  onBindClick(
    recoverPasswordData.formFields,
    "recoverPasswordCancel",
    () => !loading && onSetFormType("login")
  );

  const onRecoverPasswordSubmit = (values: FieldValues, methods: UseFormReturn) => {
    setFormObject({ values, methods });
    onRecoverPasswordRequest(values);
  };

  React.useEffect(() => {
    if (formObject && (data?.status === 204 || data?.status === 200) && !loading) {
      onRecoverPassword.onFormSuccess(formObject.values, formObject.methods, recoverPasswordData);
    }
  }, [data]);

  React.useEffect(() => {
    if (formObject && error) {
      if (error.status === 400) {
        onRecoverPassword.onFormSuccess(formObject.values, formObject.methods, recoverPasswordData);
      } else {
        toast({ message: "genericError", type: "error" });
      }
    }
  }, [error]);

  return (
    <div
      className="Recover-password"
      data-testid="Recover-password">
      <Typography
        Tag="p"
        classname="Recover-password__title"
        size="xl"
        spacing={5}>
        {recoverPasswordData.title}
      </Typography>
      <Typography
        Tag="p"
        classname="Recover-password__subtitle"
        spacing={5}>
        {recoverPasswordData.subtitle}
      </Typography>
      <Form
        fields={recoverPasswordData.formFields}
        onSubmit={onRecoverPasswordSubmit}
        schema={schema}
        validationMode="onSubmit"
        isLoading={loading && ["email", "recoverPasswordReset"]}
        isAuth
      />
    </div>
  );
}
