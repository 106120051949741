"use client";

import React from "react";

import RecoverPassword from "./RecoverPassword";
import Register from "./Register";
import Login from "./Login";

import { AuthFormComponentProps, AuthFormType } from "./types";

import "./styles.scss";

export const Auth = (props: AuthFormComponentProps) => {
  const { formType, onLogin, onRegister, onRecover, onChangeFormType, source, modalData } = props;

  const { loginData, recoverPasswordData, registerData } = modalData || {};
  const [authFormType, setAuthFormType] = React.useState<AuthFormType>(formType || "login");

  React.useEffect(() => {
    if (onChangeFormType) {
      setAuthFormType(formType);
    }
  }, [onChangeFormType]);

  return (
    <div
      data-testid="auth"
      className="Auth">
      {authFormType === "login" ? (
        <Login
          onSetFormType={setAuthFormType}
          onLogin={{
            onFormSuccess: onLogin.onFormSuccess,
            data: onLogin.data,
          }}
          loginData={loginData}
        />
      ) : authFormType === "register" ? (
        <Register
          source={source}
          onSetFormType={setAuthFormType}
          onRegister={{
            onFormSuccess: onRegister.onFormSuccess,
            data: onRegister.data,
          }}
          registerData={registerData}
        />
      ) : (
        <RecoverPassword
          onSetFormType={setAuthFormType}
          onRecoverPassword={{
            onFormSuccess: onRecover.onFormSuccess,
            data: onRecover.data,
          }}
          recoverPasswordData={recoverPasswordData}
        />
      )}
    </div>
  );
};
