"use client";

import React, { useContext } from "react";
import { ReactSVG } from "react-svg";
import { useGoogleLogin } from "@react-oauth/google";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import toaster, { Toast } from "react-hot-toast";

import { Typography } from "@/components/Typography";

import { QREditorContext } from "@/contexts/QREditorContext";

import postLoginGooglePostHandler from "@/interface/api/postLoginGoogle";
import postRegisterGooglePostHandler from "@/interface/api/postRegisterGoogle";

import { useApi } from "@/hooks/useApi";
import { Position } from "@/hooks/types";
import { useToast } from "@/hooks/useToast";
import { useLogin } from "@/hooks/auth/useLogin";

import { PostLoginResponseData } from "@/interface/api/postLogin/types";
import { PostRegisterResponseData } from "@/interface/api/postRegister/types";

import { GoogleAuthButtonProps } from "./types";

import "./styles.scss";

export const GoogleAuthToast = ({ toast, message }: { toast?: Toast; message: string }) => {
  return (
    <div className="google-auth-toast">
      <AiOutlineExclamationCircle className="google-auth-toast__icon" />
      <p className="google-auth-toast__message">{message}</p>
      <div className="google-auth-toast__button">
        <span onClick={() => toaster.dismiss(toast?.id)}>&#10005;</span>
      </div>
    </div>
  );
};

export const GoogleAuthButton = (props: GoogleAuthButtonProps) => {
  const { toast } = useToast();
  const qrEditorContext = useContext(QREditorContext);

  const [accessToken, setAccessToken] = React.useState(undefined);
  const [isGoogleAuthLoading, setIsGoogleAuthLoading] = React.useState(false);

  const { onSaveCookie } = useLogin();

  const {
    request: requestLogin,
    loading: loadingLogin,
    data: dataLogin,
    error: errorLogin,
  } = useApi<PostLoginResponseData>(postLoginGooglePostHandler.postLoginGoogle);
  const {
    request: requestRegister,
    loading: loadingRegister,
    data: dataRegister,
    error: errorRegister,
  } = useApi<PostRegisterResponseData>(postRegisterGooglePostHandler.postRegisterGoogle);

  const { type = "signin", label, errors, isFormLoading, isLoading, onSuccess } = props;

  const isButtonDisabled = loadingLogin || loadingRegister || isFormLoading;

  const handleLogin = (access_token: string) => {
    requestLogin({
      oauth_access_token: access_token,
      reference: qrEditorContext?.qrData?.qrCodeReference,
    });
  };

  const handleRegister = (access_token: string) => {
    requestRegister({
      oauth_access_token: access_token,
    });
  };

  const onAuth = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setAccessToken(codeResponse.access_token);
      type === "signin"
        ? handleLogin(codeResponse.access_token)
        : handleRegister(codeResponse.access_token);
    },
    onError: () => toast({ message: "genericError", type: "error" }),
    onNonOAuthError: (e) => {
      if (e.type === "popup_closed" || e.type === "unknown") {
        setIsGoogleAuthLoading(false);
      }
    },
    flow: "implicit",
  });

  const initAuth = () => {
    setIsGoogleAuthLoading(true);
    return onAuth();
  };

  React.useEffect(() => {
    if (dataLogin?.status === 200) {
      const token = dataLogin?.data?.token;
      const tokenExpiration = dataLogin?.data?.expiration;

      onSaveCookie(token, tokenExpiration);
      onSuccess(dataLogin);
    }
  }, [dataLogin]);

  React.useEffect(() => {
    if (dataRegister?.status === 201 && accessToken) {
      const token = dataRegister?.data?.auth?.token;
      const tokenExpiration = dataRegister?.data?.auth?.expiration;

      onSaveCookie(token, tokenExpiration);
      onSuccess(dataRegister);
    }
  }, [dataRegister]);

  React.useEffect(() => {
    if (errorLogin) {
      if (errorLogin?.status === 400 || errorLogin?.status === 401) {
        toast({
          type: "custom",
          content: (t) => (
            <GoogleAuthToast
              toast={t}
              message={errors.loginError}
            />
          ),
          position: Position.top,
          duration: 5000,
        });
      } else {
        toast({ message: errors.genericError, type: "error" });
      }
    }
  }, [errorLogin]);

  React.useEffect(() => {
    if (errorRegister) {
      if (errorRegister?.status === 400 || errorRegister?.status === 401) {
        toast({
          type: "custom",
          content: (t) => (
            <GoogleAuthToast
              toast={t}
              message={errors.registerError}
            />
          ),
          position: Position.top,
          duration: 5000,
        });
      } else {
        toast({ message: errors.genericError, type: "error" });
      }
    }
  }, [errorRegister]);

  React.useEffect(() => {
    setIsGoogleAuthLoading(loadingLogin || loadingRegister);
  }, [loadingLogin, loadingRegister]);

  React.useEffect(() => {
    isLoading(isGoogleAuthLoading);
  }, [isGoogleAuthLoading]);

  return (
    <div
      onClick={() => !isButtonDisabled && initAuth()}
      className={`google-button ${isButtonDisabled ? "google-button--disabled" : ""}`}>
      <ReactSVG
        className="google-button__icon"
        src={`/svg/icon-google-signin.svg`}
      />
      <Typography
        Tag="p"
        classname="google-button__label">
        {isButtonDisabled && !isFormLoading ? "loading..." : label}
      </Typography>
    </div>
  );
};
