"use client";

import { useContext, useEffect, useState, Fragment } from "react";
import { useRouter } from "next/navigation";
import { useCookies } from "react-cookie";

import { Button } from "@/components/Button";
import { Hamburger } from "@/components/Hamburger";
import { Wrapper } from "@/components/Wrapper";
import { Logo } from "@/components/Logo";

import { getSiteInfo } from "@/sites";

import { isClient } from "@/utils/browser";
import { paths } from "@/utils/paths";

import { removeAuthCookie } from "@/services/CookiesService";
import { AUTHORIZATION } from "@/internals/constants/cookies";

import { getSuccessRecoveryPasswordModal } from "@/modals/RecoverPasswordModal";
import { getAuthModal } from "@/modals/AuthModal";

import { LayoutContext } from "@/contexts/LayoutContext";
import { ModalContext } from "@/contexts/ModalContext";
import { AmplitudeContext } from "@/contexts/AmplitudeContext/AmplitudeContext";

import { useLogin } from "@/hooks/auth/useLogin";

import { RecoverPasswordDataProps } from "@/data/types/recover-password";
import { ShowAuthModalProps, HeaderProps } from "./types";

import "./styles.scss";

export const showAuthModal = ({
  formType,
  modalContext,
  layoutContext,
  modalData,
  onLoginSuccess,
  onRegisterSuccess,
  onRecoverPasswordSuccess,
  notClosable,
  router,
}: ShowAuthModalProps) => {
  if (!formType) modalContext.handleModal({});
  if (!layoutContext) return;

  modalContext.handleModal({
    content: getAuthModal({
      formType,
      onLogin: {
        onFormSuccess: onLoginSuccess,
      },
      onRegister: {
        onFormSuccess: onRegisterSuccess,
      },
      onRecover: {
        onFormSuccess: (_, __, data?: RecoverPasswordDataProps) => onRecoverPasswordSuccess(data),
      },
      modalData,
    }),
    notClosable,
    onCancelCallback: () => {
      if (notClosable) {
        removeAuthCookie();
        router.replace(paths.home());
        modalContext.handleModal();
      } else {
        modalContext.handleModal();
      }
    },
    modalProps: {
      className: "u-modal modal__auth u-box u-box u-box--small u-text-center",
    },
  });
};

export const Header = ({ noNav }: HeaderProps) => {
  const modalContext = useContext(ModalContext);
  const layoutContext = useContext(LayoutContext);
  const { setSource } = useContext(AmplitudeContext);

  const { isUserLoggedIn } = useLogin();
  const [ cookies ] = useCookies();
  const router = useRouter();

  const [isRegisterPage, setIsRegisterPage] = useState(false);
  const [isUserLogged, setIsUserLogged] = useState(false);

  const { siteBlackLogo28 } = getSiteInfo(process.env.NEXT_PUBLIC_DOMAIN!) || {};
  const location = isClient() ? window.location : undefined;
  const isQREditor = location?.pathname.includes("qr-editor");

  const onRecoverPasswordSuccess = (data: RecoverPasswordDataProps) => {
    modalContext.handleModal({});
    modalContext.handleModal({
      content: getSuccessRecoveryPasswordModal({
        data: data,
        onConfirmButton: () => modalContext.handleModal(),
      }),
    });
  };

  const onLoginSuccess = () => {
    !isQREditor && router.push(paths.myQrCodes());
    modalContext.handleModal();
  };

  const onRegisterSuccess = () => {
    router.push(paths.myQrCodes());
    modalContext.handleModal();
  };

  const navigateTo = (path: string) => {
    router.push(path);
    layoutContext?.setIsMenuOpened(false);
  };

  useEffect(() => {
    setIsRegisterPage(location?.pathname.includes("register"));
    setIsUserLogged(Boolean(isUserLoggedIn()));
  }, []);

  useEffect(() => {
    if (cookies?.[AUTHORIZATION]) {
      setIsUserLogged(true);
    } else {
      setIsUserLogged(false);
    }
  }, [cookies]);

  const { siteName } = getSiteInfo(process.env.NEXT_PUBLIC_DOMAIN);
  return (
    <header
      data-testid="header"
      className="header">
      <Wrapper customClass="header__wrapper">
        <Logo
          href="/"
          src={siteBlackLogo28}
          siteName={siteName}
          dataQa="header-logo-link"
          dataTestId="header-logo"
          className="header__logo"
          setSource={setSource}
        />
        {!noNav && (
          <div className="nav__wrapper">
            <Fragment>
              {!isRegisterPage && (
                <Hamburger>
                  {isUserLogged ? (
                    <Button
                      dataQA="header-dashboard-button"
                      tertiary
                      className="account"
                      label="Go to my account"
                      onClick={() => navigateTo(paths.myQrCodes())}
                    />
                  ) : (
                    <Fragment>
                      <Button
                        ghost
                        buttonId="login"
                        dataQA="header-login-button"
                        label="Login"
                        onClick={() => {
                          showAuthModal({
                            formType: "login",
                            modalContext,
                            layoutContext,
                            onLoginSuccess,
                            onRegisterSuccess,
                            onRecoverPasswordSuccess,
                            modalData: layoutContext?.modalData,
                            router,
                          });
                        }}
                      />
                      <Button
                        tertiary
                        className="signup"
                        label="Sign up"
                        dataQA="header-sign-up-button"
                        onClick={() => {
                          showAuthModal({
                            formType: "register",
                            modalContext,
                            layoutContext,
                            onLoginSuccess,
                            onRegisterSuccess,
                            onRecoverPasswordSuccess,
                            modalData: layoutContext?.modalData,
                            router,
                          });
                        }}
                      />
                    </Fragment>
                  )}
                </Hamburger>
              )}
            </Fragment>
          </div>
        )}
      </Wrapper>
    </header>
  );
};
